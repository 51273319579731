import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/reset.css";
import "./assets/css/common.css";
import "./assets/css/iconfont.css";
import "vant/lib/index.css";

import {
  Notify,
  Switch,
  Overlay,
  PullRefresh,
  Collapse,
  CollapseItem,
  Toast,
  Icon,
  NoticeBar,
  Calendar,
  ActionSheet,
  DatetimePicker,
  Form,
  Cell,
  ImagePreview,
  Row,
  Col,
  Steps,
  Step,
  Tag,
  List,
  Tabs,
  Tab,
  Image,
  Empty,
  Dialog,
  Uploader,
  Field,
  Button,
  Tabbar,
  TabbarItem,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  Picker,
  Popup,
  CellGroup,
} from "vant";

const app = createApp(App);
app
  .use(Notify)
  .use(Switch)
  .use(Overlay)
  .use(PullRefresh)
  .use(Collapse)
  .use(CollapseItem)
  .use(Toast)
  .use(Icon)
  .use(NoticeBar)
  .use(Calendar)
  .use(ActionSheet)
  .use(DatetimePicker)
  .use(Form)
  .use(CellGroup)
  .use(Cell)
  .use(ImagePreview)
  .use(Row)
  .use(Col)
  .use(Steps)
  .use(Step)
  .use(Tag)
  .use(List)
  .use(Tabs)
  .use(Tab)
  .use(Image)
  .use(Empty)
  .use(Dialog)
  .use(Uploader)
  .use(Field)
  .use(Button)
  .use(Tabbar)
  .use(TabbarItem)
  .use(RadioGroup)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Radio)
  .use(Picker)
  .use(Popup);
app.use(router).mount("#app");
