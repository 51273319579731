// 验证手机号
export const isMobile = (mobile) => {
  return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(mobile)
}

// 格式化日期
export const formatDate = date => {
  if (!date) {
    date = new Date()
  }
  let year = date.getFullYear()
  let mouth = date.getMonth() + 1
  let day = date.getDate()
  return [year, mouth, day].join('-')
}

// 获取链接参数
export const getUrlParams = name => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === name) { return pair[1]; }
  }
  return false;
}

// 判断是微信打开
export const is_weixin = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1;
}
