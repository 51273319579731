import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    children: [
      // 服务首页
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home/index.vue')
      },
      // 维修申请列表
      {
        path: '/maintain/list',
        name: 'MaintainList',
        component: () => import('../views/Apply/maintainList.vue')
      },
      //  我的证照
      {
        path: '/license/list',
        name: 'LicenseList',
        component: () => import('../views/license/list.vue')
      }
    ]
  },
  // 商户端-更改密码
  {
    path: '/pwd',
    name: 'Pwd',
    component: () => import('../views/Home/pwd.vue')
  },
  // 维修申请
  {
    path: '/maintain',
    name: 'Maintain',
    component: () => import('../views/Apply/maintain.vue')
  },
  // 维修详情
  {
    path: '/maintain/detail',
    name: 'MaintainDetail',
    component: () => import('../views/Apply/maintainDetail.vue')
  },
  // 维修申请提交成功
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Apply/success.vue')
  },
  // 证照办理
  {
    path: '/license',
    component: () => import('../views/license/index.vue'),
    children: [
      {
        path: '/license',
        redirect: '/license/build'
      },
      // 证照办理-施工证申请
      {
        path: 'build',
        name: 'LicenseBuild',
        meta: {
          title: '施工申请证'
        },
        component: () => import('../views/license/build.vue')
      },
      // 证照办理-施工证申请
      {
        path: 'build/detail',
        name: 'LicenseBuildDetail',
        meta: {
          title: '施工申请证详情'
        },
        component: () => import('../views/license/buildDetail.vue')
      },
      // 证照办理-吊车申请
      {
        path: 'crane',
        name: 'LicenseCrane',
        meta: {
          title: '吊车请证'
        },
        component: () => import('../views/license/crane.vue')
      },
      // 证照办理-施工证申请
      {
        path: 'crane/detail',
        name: 'LicenseCraneDetail',
        meta: {
          title: '吊车申请详情'
        },
        component: () => import('../views/license/craneDetail.vue')
      },
      // 证照办理-动火证申请
      {
        path: 'fire',
        name: 'LicenseFire',
        meta: {
          title: '动火证'
        },
        component: () => import('../views/license/fire.vue')
      },
      // 证照办理-动火证详情
      {
        path: 'fire/detail',
        name: 'LicenseFireDetail',
        meta: {
          title: '动火证详情'
        },
        component: () => import('../views/license/fireDetail.vue')
      },
      // 证照办理-进货申请
      {
        path: 'in/goods',
        name: 'LicenseInGoods',
        meta: {
          title: '进货申请表'
        },
        component: () => import('../views/license/inGoods.vue')
      },
      // 证照办理-进货详情
      {
        path: 'in/goods/detail',
        name: 'LicenseInGoodsDetail',
        meta: {
          title: '进货申请单详情'
        },
        component: () => import('../views/license/inGoodsDetail.vue')
      },
      // 证照办理-出货申请
      {
        path: 'out/goods',
        name: 'LicenseOutGoods',
        meta: {
          title: '出货申请表'
        },
        component: () => import('../views/license/outGoods.vue')
      },
      // 证照办理-出货申请详情
      {
        path: 'out/goods/detail',
        name: 'LicenseOutGoodsDetail',
        meta: {
          title: '出货申请单详情'
        },
        component: () => import('../views/license/outGoodsDetail.vue')
      },
      // 证照办理-建垃核销
      {
        path: 'verify',
        name: 'LicenseVerify',
        meta: {
          title: '建筑垃圾凭证核销'
        },
        component: () => import('../views/license/verify.vue')
      }
    ]
  },
  // 维修端-登录
  {
    path: '/login',
    name: 'Login',
    meta: {
      isAuth: true
    },
    component: () => import('../views/Repair/login.vue')
  },
  // 维修端-工程综合助手
  {
    path: '/repair/helper',
    name: 'Repair/Helper',
    meta: {
      isAuth: true
    },
    component: () => import('../views/Repair/helper.vue')
  },
  {
    path: '/repair',
    name: 'PublicRepair',
    meta: {
      isAuth: true
    },
    component: () => import('../views/Repair/repair.vue')
  },
  // 维修端-维修列表
  {
    path: '/repair/list',
    name: 'RepairList',
    meta: {
      isAuth: true
    },
    component: () => import('../views/Repair/list.vue')
  },
  // 维修端-维修详情
  {
    path: '/repair/detail',
    name: 'RepairDetail',
    meta: {
      isAuth: true
    },
    component: () => import('../views/Repair/detail.vue')
  },
  // 维修端-排班管理
  {
    path: '/repair/arrange',
    name: 'RepairArrange',
    meta: {
      isAuth: true
    },
    component: () => import('../views/Repair/arrange.vue')
  },
  // 维修端-证照列表
  {
    path: '/repair/license/list',
    name: 'RepairLicenseList',
    meta: {
      isAuth:  true
    },
    component: () => import('../views/Repair/licenseList.vue')
  },
  // 维修端-更改密码
  {
    path: '/repair/pwd',
    name: 'RepairPwd',
    meta: {
      isAuth:  true
    },
    component: () => import('../views/Repair/pwd.vue')
  },
  // 逾期列表
  {
    path: '/overdue',
    name: 'Overdue',
    component: () => import('../views/Repair/overdue.vue')
  },
  // 路由重定向
  {
    path: '',
    redirect: '/home'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    if (window.localStorage.getItem('user_info')) {
      to.path.includes('/login') ? next('/repair/helper') : next()
    } else {
      to.path.includes('/login') ? next() : next('/login')
    }
  } else {
    next()
  }
})

export default router
